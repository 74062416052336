import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  function setScrollPosition(){
    window.scrollTo({top: 0, left: 0, behavior: "instant"})
  }

  useEffect(() => {
    //setTimeout(() => {setScrollPosition();}, 200);
    window.scrollTo(0,0);
    
  }, [pathname]);

  return null;
}