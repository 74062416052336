import React, { useEffect, useState, Suspense} from 'react';
import { NavLink } from 'react-router-dom';
import BarLoader from 'react-spinners/PuffLoader';
import 'react-toastify/dist/ReactToastify.css';
import {getStatus} from './utils/api';
import styled from 'styled-components';
import NavBar from './components/NavBar';
import SimpleImageSlider from "react-simple-image-slider";
import Logo from "./assets/prontobau_logo.png";
import {motion} from "framer-motion";
import Banner from "./assets/banner.PNG";
import Rating from "@mui/material/Rating";
import {Canvas, useFrame, useLoader} from "@react-three/fiber";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {useGLTF, Stage, PresentationControls, OrbitControls, useFBX} from "@react-three/drei";
import Slider from "react-slick";
import {GiHouse, GiDrill, GiPaintRoller, GiClawHammer, GiFruitTree} from "react-icons/gi";
import {GoTools} from "react-icons/go";
import {FaSwimmingPool, FaTape} from "react-icons/fa";

function App() {
  const [loading, setLoading] = useState(true);
  const images = [
    { url: "https://static.wixstatic.com/media/71563fcab04c4db8853d2ea5ef555c30.jpg/v1/fill/w_1903,h_750,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/71563fcab04c4db8853d2ea5ef555c30.jpg" },
    { url: "https://static.wixstatic.com/media/e148fdba3ce94e88a7c10ab64dfbe6d1.jpg/v1/fill/w_1903,h_750,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/e148fdba3ce94e88a7c10ab64dfbe6d1.jpg" },
    { url: "https://static.wixstatic.com/media/711f4fd7e07949019fefc708066b977a.jpg/v1/fill/w_1903,h_750,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/711f4fd7e07949019fefc708066b977a.jpg" },
  ];

  const Model = () => {
    const {scene } = useGLTF("/bmw.glb");
    return <primitive object={scene} scale={0.01}/>
  }

useEffect(() => {
  setTimeout(setLoading(false), 2000);
}, []);


  return (
    <Section>
    <motion.div 
    className='container'
    initial={{opacity: 0.5}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
    >
      <div className="slider">
    <div className="sliderContent">
      <Slider infinite={true} slidesToShow={1} slidesToScroll={1} slide swipe={false} draggable={false} autoplay={true} autoplaySpeed={4000} dots={false} style={{overflow: 'hidden'}}>
        <div>
          <div style={{  backgroundRepeat: 'repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url(https://static.wixstatic.com/media/71563fcab04c4db8853d2ea5ef555c30.jpg/v1/fill/w_1903,h_750,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/71563fcab04c4db8853d2ea5ef555c30.jpg)', height: 'calc(100vh - 55px)'}}></div>
        </div>
        <div>
          <div style={{backgroundRepeat: 'no-repeat',  backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url(https://static.wixstatic.com/media/e148fdba3ce94e88a7c10ab64dfbe6d1.jpg/v1/fill/w_1903,h_750,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/e148fdba3ce94e88a7c10ab64dfbe6d1.jpg)', height: 'calc(100vh - 55px)'}}></div>
        </div>
        <div>
          <div style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url(https://static.wixstatic.com/media/711f4fd7e07949019fefc708066b977a.jpg/v1/fill/w_1903,h_750,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/711f4fd7e07949019fefc708066b977a.jpg)', height: 'calc(100vh - 55px)'}}></div>
        </div>
      </Slider>
      </div>
      </div>

    <div className="welcomeText">
      <div className="welcomeContent">
      <div className="head">
          <img src={Logo} alt="" />
          <h1>Herzlich Willkommen!</h1>
      </div>
      <div className="description">
        <p>Mit unserem Bauunternehmen haben sie als Kunde die Möglichkeiten, all unsere jahrelangen Erfahrungen und unser fachliches Wissen rund ums Bauen und Sanieren bestmöglichst zu nutzen. Profitieren Sie außerdem von den vielen Kontakten und dem hervorragenden Netzwerk aus verlässlichen und kompetenten Handwerksbetrieben.</p>
      </div>
      <NavLink className="button" to="/kontakt">
        <p>Kontaktieren Sie uns</p>
      </NavLink>
      </div>
      <div className="leistungenContainer" style={{display: 'none'}}>
        <div className="lcContent">
          <p>Poolbau</p>
          <img src="https://static.wixstatic.com/media/4d2d8fa0c42543a7b9a5ec9416597378.jpg/v1/fill/w_311,h_207,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Hotel%20Pool.jpg" alt="" />
        </div>
      </div>
    </div>


    <div className="leistungenSection">
      <div className='leistungenTitle'>
        <h1>Unsere Leistungen</h1>
        <p>Wir bieten ein breites Spektrum an Leistungen, um Ihre wertvolle Zeit sparen zu können. Kunden werden bei uns groß geschrieben, durch unsere langjährige Erfahrung und gut gepflegte Kontakte können wir Ihnen einen reibungslosen Ablauf garantieren!</p>
        </div>
      <div className="lsectionContent">
      <Slider infinite={true} slidesToShow={3} slidesToScroll={1} slide swipe={false} draggable={false} autoplay={true} autoplaySpeed={4000} dots={false} style={{zIndex: '3'}} className="sliderE"
        responsive= {[
        {
          breakpoint: 1380,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]}>
        <div>
          <div className="leistung">
            <div className="lInhalt">
            <h2>Planung</h2>
            <div className="lIcon"><GiHouse size={50} color="#5BBCB0"/></div>
          <NavLink to="/leistungen" className="btnMehr">
            <p>Mehr ...</p>
          </NavLink>
            </div>

          </div>
        </div>
        <div>

        <div className="leistung">
            <div className="lInhalt">
            <h2>Neubau</h2>
            <div className="lIcon"><GiDrill size={50} color="#5BBCB0"/></div>
          <NavLink to="/leistungen" className="btnMehr">
            <p>Mehr ...</p>
          </NavLink>
            </div>

          </div>

          
        </div>
        <div>
          
        <div className="leistung">
            <div className="lInhalt">
            <h2>Zu- & Umbau</h2>
            <div className="lIcon"><GoTools size={40} color="#5BBCB0"/></div>
          <NavLink to="/leistungen" className="btnMehr">
            <p>Mehr ...</p>
          </NavLink>
            </div>

          </div>


        </div>
        <div>
          
        <div className="leistung">
            <div className="lInhalt">
            <h2>Sanierung</h2>
            <div className="lIcon"><GiPaintRoller size={50} color="#5BBCB0"/></div>
          <NavLink to="/leistungen" className="btnMehr">
            <p>Mehr ...</p>
          </NavLink>
            </div>

          </div>



        </div>
        <div>
          
          

        <div className="leistung">
            <div className="lInhalt">
            <h2>Poolbau</h2>
            <div className="lIcon"><FaSwimmingPool size={45} color="#5BBCB0"/></div>
          <NavLink to="/leistungen" className="btnMehr">
            <p>Mehr ...</p>
          </NavLink>
            </div>

          </div>



        </div>
        <div>
          
          
        <div className="leistung">
            <div className="lInhalt">
            <h2>Beschichtungen</h2>
            <div className="lIcon"><FaTape size={45} color="#5BBCB0"/></div>
          <NavLink to="/leistungen" className="btnMehr">
            <p>Mehr ...</p>
          </NavLink>
            </div>

          </div>



        </div>
        <div>
          
          
          <div className="leistung">
              <div className="lInhalt">
              <h2>Instandhaltung</h2>
              <div className="lIcon"><GiClawHammer size={50} color="#5BBCB0"/></div>
            <NavLink to="/leistungen" className="btnMehr">
              <p>Mehr ...</p>
            </NavLink>
              </div>
  
            </div>
  
  
  
          </div>
          <div>
          
          
          <div className="leistung">
              <div className="lInhalt">
              <h2>Energieausweis</h2>
              <div className="lIcon"><GiFruitTree size={50} color="#5BBCB0"/></div>
            <NavLink to="/leistungen" className="btnMehr">
              <p>Mehr ...</p>
            </NavLink>
              </div>
  
            </div>
  
  
  
          </div>
      </Slider>
      </div>
    </div>



    <div className="testamonialSection">
    <h1 className='testamonialTitle'>Kundenbewertungen</h1>
      <div className="tContent">
      <video id="backVid" autoPlay loop muted> 
        <source src="https://video.wixstatic.com/video/11062b_9178abf22c2c4d018fb2e62936faff7b/1080p/mp4/file.mp4" type="video/mp4"/>
      </video>
          <div className="testamonial">
              <div className="testamonialUpper">
                  <Rating name="read-only" value='5' readOnly style={{position: 'absolute', top: '20px', left: '90px'}}/>
                  <p>Reibungsloser Ablauf, schnelle Umsetzung und sehr kompetente, fachliche Beratung!</p>
              </div>
              <div className="testamonialImg"><img src="https://www.dgvaishnavcollege.edu.in/dgvaishnav-c/uploads/2021/01/dummy-profile-pic.jpg" alt="" /></div>
              <div className="testamonialBottom">
                  <p>Sabine S.</p>
              </div>
          </div>

          <div className="testamonial">
              <div className="testamonialUpper">
              <Rating name="read-only" value='5' readOnly style={{position: 'absolute', top: '20px', left: '90px'}}/>
                  <p>Wir sagen nochmals Danke für die schnelle und unkomplizierte Abwicklung unseres Hausprojektes!</p>
              </div>
              <div className="testamonialImg"><img src="https://www.dgvaishnavcollege.edu.in/dgvaishnav-c/uploads/2021/01/dummy-profile-pic.jpg" alt="" /></div>
              <div className="testamonialBottom">
                  <p>Roland F.</p>
              </div>
          </div>


      </div>
    </div>
    <div className="motto">
      <div className="mottoContent">
      <img src="https://static.wixstatic.com/media/bd4388_9735aed58f34479782896370a64a0177~mv2.png" alt="" />
      <h1>"PRONTO<span>BAU<br/>weil Zeit</span> wertvoll ist."</h1>
      </div>
    </div>
    </motion.div>
    </Section>
  );
}

export default App;

/*    {
  loading?
  <>
  </>
  :
  <Canvas dpr={[1,2]} camera={{fov: 45}} style={{position: 'absolute', zIndex: 99}} draggable={false}>
  <Suspense fallback={null}>

    <OrbitControls autoRotate  enablePan={false} enableZoom={false} enableRotate={false}/>

      <Stage environment={'sunset'}>
        <Model/>
      </Stage>
    </Suspense>

  </Canvas>
}
*/
const Section = styled.section`
width: 100%;
min-height: 100%;

.sliderE{
  .slick-prev:before,
.slick-next:before {
  color: #252E32!important;
}
}
.container{
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
}
.motto{
  display: flex;
  width: 100%;
  min-height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  color: #252E32;
  font-size: 26px;
  .mottoContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 10px;

  }
  span{
    color: #5BBCB0;
  }
  img{
    height: 66px;
    width: 66px;
  }
}
.leistungenSection{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 400px;
  padding: 20px;
  .leistungenTitle{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 600px;
    color: #5BBCB0;
    text-align: start;
    gap: 20px;
    h1{
      font-size: 48px;
    }
    p{
      color: #252E32;
      font-size: 18px;
    }

  }
  .lsectionContent{
    position: relative;
    max-width: 800px;
    width: 60%;
    padding: 20px;
    .leistung{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      .lInhalt{
        width: 230px;
        height: 230px;
        background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;


      }
      .lIcon{
        height: 80px;
        width: 80px;
        border: 2.5px solid #5BBCB0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btnMehr{
        background-color: #252E32;
        padding: 5px 15px;
        border-radius: 20px;
        color: #5BBCB0;
        transition: all 0.3s;
        text-decoration: none;
      }
      .btnMehr:hover{
        background-color: #5BBCB0;
        color: #fff;
      }
    }
  }
}
.testamonialSection{
  min-height: 300px;
  width: 100%;
  //background: url("https://img.freepik.com/free-photo/white-blank-background-texture-design-element_53876-121611.jpg?w=2000");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  .testamonialTitle{
    background-image: url(${Banner});
    background-size: cover;
    width: 100%;
    min-height: 120px;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 36px;
  }
  .tContent{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 50px;
    position: relative;
    background-color: #000;
  }
  #backVid{
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 0.2;

  }
  .testamonial{
      height: 320px;
      width: 300px;
      background-color: #ffff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
    .testamonialUpper{
      width: 100%;
      height: 220px;
      padding: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      position: relative;
      color: #252E32;
    }
    .testamonialImg{
      border-radius: 50%;
      background-color: #5BBCB0;
      position: absolute;
      left: 100px;
      bottom: 50px;
      height: 100px;
      width: 100px;
    }
    img{
      height: 100px;
      width: 100px;
      border-radius: 50%;
      border: 4px solid #5BBCB0;
    }
    .testamonialBottom{
      width: 100%;
      height: 100px;
      background-color: #5BBCB0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 20px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: end;
    }
  }
}
.leistungenContainer{
  border-radius: 10px;
  border: 4px solid #5BBCB0;
  padding: 10px;
  width: 100%;
  max-width: 650px;
  .lcContent{
    display: grid;
    
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    p{
      color: #5BBCB0;
      font-weight: 900;
      self-align: center;
      align-self: center;
      text-align: center;
    }
    img{
      border-radius: 5px;
    }
  }
}
.leistungenContainer::after{
  border-radius: 5px;
}
.welcomeText{
  z-index: 2;
  min-height: calc(100vh - 55px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  padding: 20px;
  .welcomeContent{
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    text-align: center;
  }
  .head{
      h1{
        color: #5BBCB0;
        font-size: 50px;
      }
  }
  .description{
    p{
      color: #fff;
    }
  }
  .button{
    padding: 5px 10px;
    background-color: #5BBCB0;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
  .button{
    background-color: #5BBCB0;
    padding: 10px 30px;
    border-radius: 20px;
    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
}
.button:hover{
    background-color: #252E32;
    color: #5BBCB0;
}
}
.slider{
  background-color: #000;
  width: 100%;
  position: absolute;
  height: calc(100vh - 55px);
  .sliderContent{
    opacity: 0.2;

  }
}


@media screen and (min-width: 280px) and (max-width: 900px) {
.leistungenSection{
  flex-direction: column;
  gap: 20px;
  .leistungenTitle{
    text-align: center;
  }
  .lsectionContent{
    width: 100%;
  }
}
.tContent{
  flex-direction: column;
}

}

.testamonialTitle{
  margin-bottom: -1px;
}
`;
