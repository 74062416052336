import React, { useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import SimpleImageSlider from "react-simple-image-slider";
import Logo from "../assets/prontobau_logo.png";
import Banner from "../assets/banner.PNG";
import { Fade, LightSpeed, Bounce, Slide, Zoom } from 'react-reveal';
import {motion} from "framer-motion";

function App() {

useEffect(() => {

}, []);



  return (
    <Section>
    <motion.div className='container'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}>
        <h1 className='title'>Kontakt</h1>
        <div className="lcContainer">
                    <Zoom duration={800}>
                    <div className="lc">
                        <div className="lcContent">
                        <h1 className='headText'>
                            <img src="https://static.wixstatic.com/media/bd4388_9735aed58f34479782896370a64a0177~mv2.png/v1/fill/w_50,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/prontobau_symbol02.png" alt="" />
                            Wir freuen uns auf Ihre Anfrage!
                        </h1>
                        <img src="https://static.wixstatic.com/media/bd4388_8f3587ba17b442d5a746bb736062a226~mv2.jpg/v1/fill/w_847,h_565,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/prontbau_stefanriepl.jpg" alt="" className='image'/>
                        <div className="description">
                            <p><span>Telefon Stefan Riepl:</span> <a href="tel:+436643438132">+43 664 - 34 38 132</a><br/><br/><span>PRONTO Bau GmbH</span><br/>Harrstraße 2, A-4470 Enns (OÖ)<br/><span>M:</span> <a href='mailto:office@pronto-bau.at?subject=Anfrage'>office@pronto-bau.at</a></p>
                        </div>
                        </div>
                </div>
                </Zoom>
                <a href="mailto:office@pronto-bau.at?subject=Anfrage" className="mailBtn">
                    <p>E-Mail senden</p>
                </a>
                <iframe className='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2659.968258880649!2d14.464485615835933!3d48.18796305562893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4773b9950c028003%3A0xb8a43855e9cf7529!2sHarrstra%C3%9Fe%202%2C%204470%20Enns%2C%20%C3%96sterreich!5e0!3m2!1sde!2sde!4v1668326070514!5m2!1sde!2sde"  height="450" style={{border: 0, width: '100vw' }}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </motion.div>
    </Section>
  );
}

export default App;

const Section = styled.section`
width: 100%;
min-height: 100%;
overflow-x: hidden;

.mailBtn{
    background-color: #252E32;
    padding: 10px 30px;
    border-radius: 20px;
    color: #5BBCB0;
    transition: all 0.3s;
    text-decoration: none;
}
.mailBtn:hover{
    background-color: #5BBCB0;
    color: #fff;
}
.container{
    padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
justify-content: center;
align-items: center;
text-align: center;
  height: 100%;
  width: 100%;
.title{
    background-image: url(${Banner});
    background-size: cover;
    width: 100vw;
    min-height: 120px;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

}
  h1{
    color: #5BBCB0;
    font-size: 40px;
  }
}
.lcContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.lc{
    background-color: #fff;
    border-radius: 5px;
    max-width: 900px;
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 30px;
    .lcContent{s
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .headText{
        display: flex;
        gap: 10px;
        align-items: center;
        font-weight: 400;
        font-size: 30px;
        top: 0;
        left: 0;
    }
    .description{
        width: 100%;
        display: flex;
        justify-content: center;

        span{
            color: #5BBCB0;
        }
        a{
            color: #000;
        }
    }
    .image{
        width: 100%;
        right: 0;
        top: 41.5px;
        border-radius: 3px;
    }
}
@media screen and (min-width: 280px) and (max-width: 1080px) {

.lc{
    padding: 10px;
}
.lcContainer{
    padding: 10px;
}
.maps{
    margin-bottom: -10px;
}

`;
