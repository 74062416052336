import { Outlet } from "react-router-dom";
import React, { useEffect, useState} from "react";
import Navbar from "./NavBar";
import Footer from "./Footer";
import Dialog from '@mui/material/Dialog';
import { PulseLoader } from "react-spinners";
import Fade from "@mui/material/Fade";


export default function NavbarLayout () {
    useEffect(() => {
      }, []);

    return (
        <div>
            <Navbar/>
            <div style={{width: '100%', paddingTop: '55px', height: '100vh'}}>
            <Outlet/>
            <Footer/>
            </div>
        </div>
    )
}