import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import App from './App';
import Test from './Test';
import {ToastContainer, toast} from 'react-toastify';
import GlobalStyle from './globalStyles';
import ScrollToTop from "./scrollToTop";
import NavBarLayout from "./components/NavBarLayout";
import Leistungen from "./pages/leistungen";
import Unternehmen from "./pages/unternehmen";
import AnimatedRoutes from './AnimatedRoutes';

const Index = () => (
  <div>
  <Router>
    <GlobalStyle/>
    <ScrollToTop/>
    <AnimatedRoutes/>
  </Router>
  <ToastContainer/>
  </div>
);
ReactDOM.render(<Index/>, document.getElementById('root'));


