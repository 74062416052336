import React from "react";

import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import App from './App';
import Test from './Test';
import NavBarLayout from "./components/NavBarLayout";
import Leistungen from "./pages/leistungen";
import Unternehmen from "./pages/unternehmen";
import Kontakt from "./pages/kontakt";
import Impressum from "./pages/impressum";
import Datenschutz from "./pages/datenschutz";

import {AnimatePresence} from 'framer-motion';
function AnimatedRoutes(){
    const location = useLocation();
    return(
        <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
        <Route element={<NavBarLayout/>}>
        <Route path="/" element={<App/>}/>
        <Route path="/kontakt" element={<Kontakt/>}/>
        <Route path="/leistungen" element={<Leistungen/>}/>
        <Route path="/unternehmen" element={<Unternehmen/>}/>
        <Route path="/impressum" element={<Impressum/>}/>
        <Route path="/datenschutz" element={<Datenschutz/>}/>
        </Route>
      </Routes>
      </AnimatePresence>
    )
}
export default AnimatedRoutes