import { createGlobalStyle } from "styled-components";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const GlobalStyle = createGlobalStyle`
:root {
    --background-color: #0e0e10;
    --font-family: 'Nunito', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    color: #000;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    background-image: url(https://img.freepik.com/free-photo/paper-textured-background_53876-30486.jpg?w=2000);
//https://static.vecteezy.com/system/resources/previews/000/527/711/non_2x/white-fabric-textured-seamless-background-abstract-white-pattern-vector.jpg
//https://www.designbolts.com/wp-content/uploads/2012/12/Simple-Horizontal-Seamless-Pattern-For-Website-Background.jpg
background-repeat: repeat;
background-position: cover;
background-attachment: fixed;
}

`;

export default GlobalStyle;