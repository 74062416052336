import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {MdOutlineShoppingBag, MdOutlineEmail, MdOutlinePhone} from "react-icons/md";
import Logo from "../assets/prontobau_logo.png";
import LogoText from "../assets/prontobau_logo_text.png";
import TextTransition, {presets} from "react-text-transition";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {ImCross} from "react-icons/im";
import {BiMenu} from "react-icons/bi";

export default function Navbar() {
  const [drawerState, setDrawerState] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      8000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  const [index, setIndex] = useState(0);
  const TEXTS = [
    'SAVE 20% WITH THE CODE "FASHION"',
    "WE ARE THE FUTURE OF FASHION",
  ];
  const navLinkFunc = ({ isActive }) => (isActive ? "nav-link-active" : "nav-link");
  const navLinkFuncHighlight = ({ isActive }) => (isActive ? "nav-link-highlight-active" : "nav-link-highlight");

  const drawer = (
    <Drwer>
      <div className="drawerContainer">
        <ImCross className= "Xicon" color="#5BBCB0" onClick={() => setDrawerState(false)}/>
        <NavLink to="/" className={navLinkFunc} id="linkNav">HOME</NavLink>
                <NavLink to="/leistungen" className={navLinkFunc} id="linkNav" onClick={() => setDrawerState(false)}>LEISTUNGEN</NavLink>
                <NavLink to="/unternehmen" className={navLinkFunc} id="linkNav" onClick={() => setDrawerState(false)}>UNTERNEHMEN</NavLink>
                <NavLink to="/kontakt" className={navLinkFunc} id="linkNavHighlight" onClick={() => setDrawerState(false)}>KONTAKT</NavLink>
      </div>
    </Drwer>
  );

  return (
    <Nav>
        <SwipeableDrawer
        anchor='top'
        open={drawerState}
        onClose={() => setDrawerState(false)}
        sx={{'& .MuiDrawer-paper': {boxSizing: 'border-box', width: '100%', height: "100%", backgroundColor: '#252E32'}}}>
          {drawer}
      </SwipeableDrawer>
      <div className="banner">
        <p>      
        <TextTransition springConfig={presets.default} style={{textAlign: "center", display: "flex", justifyContent: "center"}}>
        {TEXTS[index % TEXTS.length]}
        </TextTransition>
      </p>
      </div>
      <div className="nav">
        <div className="nav-content">

            <NavLink to="/" className="nav-title">
                <img src={Logo} alt="" />
                <img src={LogoText} alt="" />
            </NavLink>
            <div className="nav-links-main">
                <NavLink to="/" className={navLinkFunc} id="linkNav">HOME</NavLink>
                <NavLink to="/leistungen" className={navLinkFunc} id="linkNav">LEISTUNGEN</NavLink>
                <NavLink to="/unternehmen" className={navLinkFunc} id="linkNav">UNTERNEHMEN</NavLink>
                <NavLink to="/kontakt" className={navLinkFunc} id="linkNavHighlight">KONTAKT</NavLink>
            </div>
            <div className="nav-links-right">
                <a href="tel:+436643947547" className="nav-link" id="linkNav"><MdOutlinePhone size={28} color="#fff"/></a>
                <a href="mailto:office@pronto-bau.at" className="nav-link"id="linkNav"><MdOutlineEmail size={28} color="#fff"/></a>
            </div>
            <div className="nav-links-right-mobile">
                <BiMenu size={34} color="#fff" style={{cursor: 'pointer'}} onClick={() => setDrawerState(true)}/>
            </div>
        </div>

        </div>
    </Nav>
    
  );
  
}
const Drwer = styled.div`
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
.drawerContainer{
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  font-size: 18px;
}
.Xicon{
  cursor: pointer;
}
.nav-link {
  display: flex;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  font-size: 18px;
  color: #fff;
}
.nav-link-highlight{
  display: flex;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  padding: 4px 8px;
  border-radius: 3px;
}
.nav-link-active{
  display: flex;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  font-size: 18px;
  color: #5BBCB0;
}
.nav-link-highlight-active{
  display: flex;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  font-size: 14px;
  background-color: #5BBCB0;
  color: #000;
  padding: 4px 8px;
  border-radius: 3px;
}

.nav-links a:hover {
  color: #000;
}

.nav-links a:hover i {
  border-color: #fff;
}
`;

const Nav = styled.nav`
position: fixed;
width: 100%;
z-index: 5;
.banner{
  height: 35px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  p{
    color: #fff;
    font-size: 12px;
    font-weight: 900;
  }
}
.nav {
  height: 55px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #252E32;
}
.nav-content{
  display: flex;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap; /*Recommended property*/
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.nav-title {
  display: inline-block;
  font-size: 22px;
  display: flex;
  align-items: center;
  width: 200px;
  img{
    height: 35px;
  }
}
.nav-links-main {
  display: flex;
  gap: 35px;
  align-items: center;
  float: right;


}
.nav-links-right {
  width: 200px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  float: right;
}
.nav-links-right-mobile{
  width: auto;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  float: right;
  display: none;
}

.nav-link {
  display: flex;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
}
.nav-link-highlight{
  display: flex;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  padding: 4px 8px;
  border-radius: 3px;
}
.nav-link-active{
  display: flex;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  font-size: 14px;
  color: #5BBCB0;
}
.nav-link-highlight-active{
  display: flex;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  font-size: 14px;
  background-color: #5BBCB0;
  color: #000;
  padding: 4px 8px;
  border-radius: 3px;
}

.nav-links a:hover {
  color: #000;
}

.nav-links a:hover i {
  border-color: #fff;
}

@media screen and (min-width: 280px) and (max-width: 900px) {
  .nav-links-main{
    display: none;
  }
  .nav-title{
    width: auto;
  }
  .nav-links-right{
    width: auto;
  }
  .nav-links-right-mobile{
    display: inherit;
  }
  .nav-links-right{
    display: none;
  }
}
`;

