import React, { useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import SimpleImageSlider from "react-simple-image-slider";
import Logo from "../assets/prontobau_logo.png";
import Banner from "../assets/banner.PNG";
import { Fade, LightSpeed, Bounce, Slide, Zoom } from 'react-reveal';
import {motion} from "framer-motion";

function App() {

    const leistungen = [
        { 
            title: "Neubau, Zu- & Umbau",
            description: "Von der professionellen Planung und Ausschreibung bis zur Baukoordination und Bauaufsicht vor Ort. Alles aus einem Haus, sodass Sie sich um nichts kümmern müssen. Profitieren Sie von unseren langjährigen Erfahrungen und einem großen, sorgfältig ausgewählten Netzwerk an Partnern mit denen wir Ihnen einen  reibungslosen Ablauf Ihres Projekts zu jeder Zeit nur garantieren können. Wir freuen uns auf Ihre Anfrage!",
            url: "https://static.wixstatic.com/media/cd5b640880ce4ca3a1d3ba165d8ee82d.jpg/v1/fill/w_311,h_207,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Wei%C3%9Fes%20Haus%20mit%20Bush.jpg" 
        },
        { 
            title: "Poolbau",
            description: "Unsere langjährige Erfahrung im Poolbau garantiert Ihnen eine fachgerechte Beratung und eine technisch einwandfreie Auführung.",
            additional: "Wir erledigen für Sie außerdem alle administrativen Arbeiten, stimmen alle Komponenten aufeinander ab und garantieren Ihnen eine termingerechte Fertigstellung in hoher Qualität. Wir sorgen für Ihren kühlen Badespaß an heißen Tagen:)!",
            url: "https://static.wixstatic.com/media/4d2d8fa0c42543a7b9a5ec9416597378.jpg/v1/fill/w_311,h_207,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Hotel%20Pool.jpg" 
        },
        { 
            title: "Beschichtungen",
            description: "Wir sind spezialisiert auf viele Sonderlösungen, ganz besonders auf Beschichtungen jeglicher Art.",
            additional: "Bei einer Beschichtung kann es sich um eine dünne Schicht oder eine dicke Schicht sowie um mehrere in sich zusammenhängende Schichten handeln und orientiert sich am Beschichtungsverfahren und Anwendungszweck. Für Garageböden, Industrieböden, Aluträger, Wände...uvm. !",
            url: "https://static.wixstatic.com/media/11062b_49866bb72f324fd5819c06d9acc77c50~mv2.jpg/v1/fill/w_311,h_207,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Bauarbeiter.jpg" 
        },
        { 
            title: "Sanierung, Instandsetzung",
            description: "Sie brauchen ein neues Dach, möchten Ihr Heizsystem wechseln oder denken daran ihre  Fassade oder Fenster an ihrem Haus zu erneuern? Dann sind Sie bei uns genau richtig:).",
            additional: "Kontaktieren Sie uns für ein kostenloses Erstgespräch und wir kommen völlig unverbindlich zu Ihnen um alles im Detail persönlich vor Ort zu besprechen.",
            url: "https://static.wixstatic.com/media/5aa9ab0d41b44713a78b4e9c7fa4ce3f.jpg/v1/fill/w_311,h_207,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Bauarbeiter%20bei%20Sonnenuntergang.jpg" 
        },
        { 
            title: "Energieausweis",
            description: "Der Energieausweis ist ein Dokument, das Daten zur Energieeffizienz und zu den anfallenden Energiekosten eines Gebäudes liefern soll.",
            additional: "Wir haben die Befähigung um Ihnen Ihren Energieausweis für Wohn- und Nichtwohngebäude sowie für Neubau-, Sanierungs- bzw. Bestandsobjekte auszustellen.",
            url: "https://static.wixstatic.com/media/82c08d3efb804aa5a2af21ab98acde29.jpg/v1/fill/w_311,h_207,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Nachhaltige%20Energie.jpg" 
        },
      ];

useEffect(() => {

}, []);



  return (
    <Section>
    <motion.div className='container'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}>
        <h1 className='title'>Leistungen</h1>
        <div className="lcContainer">
        {
            leistungen.map((l) =>{
                return(
                    <Zoom duration={800}>
                    <div className="lc">
                        <div className="lcContent">
                        <h1 className='headText'>
                            <img src="https://static.wixstatic.com/media/bd4388_9735aed58f34479782896370a64a0177~mv2.png/v1/fill/w_50,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/prontobau_symbol02.png" alt="" />
                            {l.title}
                        </h1>
                        <p className='description'>
                            {l.description}
                            {!l.additional?
                            <></>
                            :
                            <>
                            <br/>
                            <br/>
                            {l.additional}
                            </>
                            }
                        </p>
                        <img src={l.url} alt="" className='image'/>
                        </div>
                </div>
                </Zoom>
                );
            })
        }
        </div>
    </motion.div>
    </Section>
  );
}

export default App;

const Section = styled.section`
width: 100%;
min-height: 100%;
overflow-x: hidden;
.container{
    padding-top: 0px;
    margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
justify-content: center;
align-items: center;
text-align: center;
  height: 100%;
  width: 100%;
.title{
    background-image: url(${Banner});
    background-size: cover;
    width: 100vw;
    min-height: 120px;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

}
  h1{
    color: #5BBCB0;
    font-size: 40px;
  }
}
.lcContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.lc{
    background-color: #fff;
    border-radius: 5px;
    max-width: 900px;
    width: 100%;
    display: flex;
    gap: 20px;
    height: 350px;
    padding: 30px;
    .lcContent{
        position: relative;
        height: 100%;
        width: 100%;
    }
    .headText{
        position: absolute;
        display: flex;
        gap: 10px;
        align-items: center;
        font-weight: 400;
        font-size: 30px;
        top: 0;
        left: 0;
    }
    .description{
        text-align: start;
        position: absolute;
        max-width: 500px;
        top: 65px;
        
    }
    .image{
        height: 207px;
        position: absolute;
        right: 0;
        top: 41.5px;
        border-radius: 3px;
    }
}
@media screen and (min-width: 280px) and (max-width: 1080px) {
.lcContainer{
    padding: 10px;
}
.lc{
    height: 550px;
    max-width: 373px;
    padding: 10px;
    .headText{
        flex-direction: column;
        width: 100%;
        font-size: 24px;
        gap: 6px;
        top: 10px;
    }
    .image{
        top: 110px;
        width: calc(100vw - 40px);
        max-width: 353px;
        left: 0px;
    }
    .description{
        top: 340px;
        font-size: 13px;
        text-align: center;
        width: 100%;
    }
}

}

`;
