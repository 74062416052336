import React, { useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import SimpleImageSlider from "react-simple-image-slider";
import Logo from "../assets/prontobau_logo.png";
import Banner from "../assets/banner.PNG";
import { Fade, LightSpeed, Bounce, Slide, Zoom } from 'react-reveal';
import {motion} from "framer-motion";

function App() {

    const leistungen = [
        { 
            title: "Stefan Riepl",
            description: "Maurer -> Vorarbeiter -> Polier -> Techniker -> Bauleiter: Stefan Riepl ist bereits seit über 25 Jahren von Anfang an im Baugewerbe tätig. Nach der erfolgreichen Absolvierung der Hochbaulehre und der darauffolgenden Ausbildung zum Werksmeister wurden jahrelange Erfahrungen in Mittel- und Großbetrieben gesammelt. Weitere abgeschlossene Schulungen wie die der Lehrlingsausbildung, Vermessungstechnik, Instandsetzung und als SVP (Sicherheits- & Vertrauensperson) zeichnen Stefan zusätzlich aus und ist Ansprechpartner für alle Angelegenheiten rund um den Bau.",
            url: "https://static.wixstatic.com/media/bd4388_8f3587ba17b442d5a746bb736062a226~mv2.jpg/v1/crop/x_323,y_50,w_4291,h_2853/fill/w_311,h_207,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/prontbau_stefanriepl.jpg" 
        },
        { 
            title: "Ihre Vorteile",
            description: "Alles aus einer Hand: Wir bauen, beaufsichtigen, kümmern uns um alle Kosten und technischen Abnahmen, rund um die Planungsarbeiten profitieren Sie von unserem jahrelang aufgebauten, hervorragenden Netzwerk aus verschiedenen Professionisten - sofern wir nicht selbst anpacken sollten:)",
            additional: "Wir können für einen reibungslosen Ablauf Ihres Bauvorhabens nur garantieren. Zuverlässigkeit, Transparenz und Ehrlichkeit stehen bei uns an erster Stelle.",
            url: "https://static.wixstatic.com/media/11062b_8278a66c318043ad812039533fa6ee0a~mv2.jpg/v1/fill/w_311,h_207,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Bauarbeiter%20Silhouette.jpg" 
        },
      ];

useEffect(() => {

}, []);



  return (
    <Section>
    <motion.div className='container'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}>
        <h1 className='title'>Über uns</h1>
        <div className="lcContainer">
        {
            leistungen.map((l) =>{
                return(
                    <Zoom duration={800}>
                    <div className="lc">
                        <div className="lcContent">
                        <h1 className='headText'>
                            <img src="https://static.wixstatic.com/media/bd4388_9735aed58f34479782896370a64a0177~mv2.png/v1/fill/w_50,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/prontobau_symbol02.png" alt="" />
                            {l.title}
                        </h1>
                        <p className='description'>
                            {l.description}
                            {!l.additional?
                            <></>
                            :
                            <>
                            <br/>
                            <br/>
                            {l.additional}
                            </>
                            }
                        </p>
                        <img src={l.url} alt="" className='image'/>
                        </div>
                </div>
                </Zoom>
                );
            })
        }
        </div>
    </motion.div>
    </Section>
  );
}

export default App;

const Section = styled.section`
width: 100%;
min-height: 100%;
overflow-x: hidden;
.container{
    padding-top: 0px;
    margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
justify-content: center;
align-items: center;
text-align: center;
  height: 100%;
  width: 100%;
.title{
    background-image: url(${Banner});
    background-size: cover;
    width: 100vw;
    min-height: 120px;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

}
  h1{
    color: #5BBCB0;
    font-size: 40px;
  }
}
.lcContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.lc{
    background-color: #fff;
    border-radius: 5px;
    max-width: 900px;
    width: 100%;
    display: flex;
    gap: 20px;
    height: 350px;
    padding: 30px;
    .lcContent{
        position: relative;
        height: 100%;
        width: 100%;
    }
    .headText{
        position: absolute;
        display: flex;
        gap: 10px;
        align-items: center;
        font-weight: 400;
        font-size: 30px;
        top: 0;
        left: 0;
    }
    .description{
        text-align: start;
        position: absolute;
        max-width: 500px;
        top: 65px;
        font-size: 15px;
        
    }
    .image{
        height: 207px;
        position: absolute;
        right: 0;
        top: 41.5px;
        border-radius: 3px;
    }
}
@media screen and (min-width: 280px) and (max-width: 1080px) {
.lcContainer{
    padding: 10px;
}
.lc{
    height: 550px;
    padding: 10px;
    max-width: 373px;
    .headText{
        flex-direction: column;
        width: 100%;
        font-size: 24px;
        gap: 6px;
    }
    .image{
        top: 100px;
        width: calc(100vw - 40px);
        max-width: 353px;
        left: 0;
    }
    .description{
        top: 320px;
        font-size: 12px;
        text-align: center;
        width: 100%;
    }
}

}

`;
