import React, { useEffect, useState} from 'react';
import styled from 'styled-components';


function App() {


useEffect(() => {

}, []);


  return (
    <Section>
    <div className='container'>
        <div className="header">
          <div className="logo">
            <img height='50px' src='https://static.wixstatic.com/media/bd4388_8d6e66d913c142f3840adec0dc9a5183~mv2.png/v1/fill/w_71,h_69,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/prontobau_symbol01.png'/>
            <img height='50px' src='https://static.wixstatic.com/media/bd4388_67f7a931a47248e4abe1f62d7a273a96~mv2.png/v1/fill/w_287,h_62,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/prontobau_logo_schrift.png'/>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default App;

const Section = styled.section`
width: 100%;
min-height: 100vh;
height: auto;
background: linear-gradient(grey, black);
.container{
  display: flex;
  flex-direction: column;

  .header{
    height: 60px;
    display: flex;
    align-items: center;

    .logo{
      display: flex;
      align-items: center;
    }
  }
}

`;
