import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {MdFacebook} from "react-icons/md";
import Logo from "../assets/prontobau_logo.png";
import LogoText from "../assets/prontobau_logo_text.png";
import TextTransition, {presets} from "react-text-transition";

export default function Navbar() {
  useEffect(() => {
  }, []);

  return (
    <Foot>
      <div>
        <div className="top">
          <div className="topC">
          <div className="left">
            <img src={LogoText} alt="" />
            <a href="https://www.facebook.com/prontobau.gmbh"><MdFacebook size={25}/></a>
          </div>  
          <div className="right">
              <h2>Kontakt</h2>
              <p>Tel: <a href="tel:+436643947547">+43 664 34 38 132</a></p>
              <p>Mail: <a href="mailto:office@pronto-bau.at">office@pronto-bau.at</a></p>
          </div>
          <div className="right">
            <h2>Rechtliches</h2>
            <NavLink to="/impressum" className="navLink">Impressum</NavLink>
            <NavLink to="/datenschutz" className="navLink">Datenschutz</NavLink>
          </div>
          <div className="right">
            <h2>Unternehmen</h2>
            <p>PRONTO Bau GmbH</p>
            <p>Harrstraße 2, A-4470 Enns</p>
          </div>
          </div>
        </div>
        <div className="bottom">
          <p>© {new Date().getFullYear()} - PRONTO Bau GmbH</p>
        </div>
      </div>
    </Foot>
    
  );
  
}

const Foot = styled.footer`
width: 100%;
height: 195px;
background-color: #252E32;
a{
  color: #fff;
}
.navLink{
  text-decoration: none;
}
.top{
  width: 100%;
  height: 160px;
  color: #fff;
  display: flex;
  justify-content: center;
  .topC{
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    max-width: 1200px;
  }
  p{
    font-size: 14px;
  }
  .left{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    justify-content: center;
    align-items: center;
    img{
      height: 40px;
      width: 200px;
    }
    a{
      color: #fff;
    }
  }
  .right{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2{
      color: #5bbcb0;
      padding-bottom: 5px;
      font-size: 20px;
    }
  }
}
.bottom{
  padding: 0 40px;
background-color: #5bbcb0;
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  p{
    font-size: 14px;
    width: 200px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .links{
    display: flex;
    gap: 20px;
    .link{
      text-decoration: none;
      color: #fff;
    }
  }
  .socials{
    width: 200px;
    display: flex;
    justify-content: end;
    align-items: center;
    a{
      text-decoration: none;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (min-width: 280px) and (max-width: 1080px) {
  height: auto;
  text-align: center;
  .top{
    height: auto;
  }
  .topC{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .right{
    width: 175px;
  }
}
`;

